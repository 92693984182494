var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"wizard-vertical mb-3 pb-1",attrs:{"color":"#7367F0","title":null,"subtitle":null,"layout":"vertical","finish-button-text":"Add Admin","back-button-text":"Go Back"},on:{"on-complete":_vm.addAdmin}},[_c('tab-content',{attrs:{"title":"Account Details","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Account Details ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Enter admin account details ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"firstname"}},[_vm._v("Firstname ")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstname","state":errors.length > 0 ? false:null,"name":"firstname","placeholder":"Firstname"},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"lastname"}},[_vm._v("Lastname ")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastname","state":errors.length > 0 ? false:null,"name":"lastname","placeholder":"Lastname"},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"description":"The admin username must be unique"}},[_c('label',{attrs:{"for":"username"}},[_vm._v("Username ")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false:null,"name":"username","placeholder":"Username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"password"}},[_vm._v("Password ")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","type":"password","state":errors.length > 0 ? false:null,"name":"password","placeholder":"............"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Status ")]),_c('br'),_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"switch":"","inline":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)])],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Permissions"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Permissions ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Select account permissions ")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticClass:"border",attrs:{"no-body":""}},[_c('b-table',{staticClass:"mb-0",attrs:{"striped":"","responsive":"","fields":_vm.fields,"items":_vm.permissionsData},scopedSlots:_vm._u([{key:"cell(module)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(roles)",fn:function(data){return [_c('b-form-checkbox-group',{attrs:{"options":data.value,"value-field":"item","text-field":"name"},model:{value:(_vm.roles),callback:function ($$v) {_vm.roles=$$v},expression:"roles"}})]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }